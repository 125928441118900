import React from 'react';
import { Link } from 'gatsby';

import opEnum from '../../services/enum';

import SeoImage from '../../static/images/png/op-mains.jpg';

import {
  Layout, Typography, Separator, Image,
} from '../../components';

const ChirurgieDeLaMain = () => (
  <Layout
    title="Opérations de la main : toutes les interventions du Dr Ghazanfari"
    image={SeoImage}
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-12 space-y-4 text-center bg-white rounded">
      <Typography className="uppercase tracking-[0.3em]" variant="h1" color="secondary-deep" size="lg" weight="bold">Chirurgie de la main</Typography>
    </div>
    <Image className="xl:h-[600px]" imageName="op-mains.jpg" />
    <div className="flex flex-col px-4 py-16 mx-auto space-y-12 max-w-screen-xlg">
      <div className="flex flex-col space-y-4 text-justify">
        <Typography>
          La chirurgie de la main est une spécialité à part au sein de la chirugie orthopédique.
          Il s&apos;agit d&apos;une chirugie très minutieuse et délicate qui devrait être réalisé
          par un
          {' '}
          <Typography className="underline" variant="span" hoverColor="link"><Link to="/">chirurgien de la main confirmé.</Link></Typography>
          {' '}
          Titulaire du diplôme de chirurgie de la
          main et du diplôme de microchirurgie, le Docteur Ghazanfari est un
          {' '}
          <Typography variant="span" weight="bold">Chirurgien spécialiste de la main à Paris</Typography>
          {' '}
          qui utilise les techniques chirurgicales de pointe.
        </Typography>
      </div>
      <div className="flex flex-col space-y-4 text-justify">
        <Typography className="uppercase" variant="h2" color="primary" size="mdxl" weight="semiBold">Qu&apos;est ce que la chirurgie de la main ?</Typography>
        <Separator />
        <Typography>
          Les pathologies de la main et du poignet sont nombreuses, en voici quelques
          exemples :
        </Typography>
        <ul className="flex flex-col">
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Syndrome du canal carpien</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Doigt à ressaut</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Tendinite de De Quervain</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Pseudokyste mucoïde</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Kyste arthro-synovial</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">La maladie de Dupuytren</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Arthrose des doigts</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Rhizarthrose ou arthrose du pouce</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Fracture du scaphoïde</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Pseudarthrose du scaphoïde</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Arthrose du poignet</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Entorse du poignet</Typography>
          </li>
        </ul>
        <Typography>
          Toute pathologie de la main négligée ou mal traitée peut avoir d&apos;importantes
          conséquences fonctionnelles et esthétiques, parfois définitives.
        </Typography>
        <Typography>
          Lors de votre première consultation avec le
          {' '}
          <Typography variant="span" weight="bold">spécialiste de la main</Typography>
          ,
          l&apos;interrogatoire,
          l&apos;examen clinique et les éventuels examens complémentaires (radiographies,
          scanner, IRM...) permettent de faire le
          {' '}
          <Typography variant="span" weight="bold">diagnostic précis</Typography>
          {' '}
          de votre pathologie.
        </Typography>
        <Typography>
          Un traitement, médical (attelle, rééducation, infiltration…) ou chirurgical, vous sera
          ensuite conseillé selon votre pathologie.
        </Typography>
        <Typography>
          Durant cette prise en charge, l&apos;information du
          patient est primordiale afin d&apos;instaurer
          un climat de confiance et de simplifier le parcours de soins.
        </Typography>
        <Typography>
          C&apos;est dans cette optique que le Docteur Ghazanfari vous explique en détails les
          différentes pathologies, leurs traitements et les différentes interventions
          chirurgicales pratiquées sur des « fiches patient » que vous pouvez trouver sur son
          site internet.
        </Typography>
      </div>
      <div className="flex flex-col space-y-4 text-justify">
        <Typography className="uppercase" variant="h2" color="primary" size="mdxl" weight="semiBold">Comment choisir un chirurgien de la main à Paris ?</Typography>
        <Separator />
        <Typography>
          La chirurgie de la main est particulièrement complexe et
          {' '}
          <Typography variant="span" weight="bold">les interventions nécessitant une grande minutie et délicatesse</Typography>
          . Vous devez donc impérativement faire appel à un spécialiste expérimenté dans
          ce domaine.
        </Typography>
        <Typography>Votre chirurgien de la main doit :</Typography>
        <ul className="flex flex-col">
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">Être titulaire du Droit au Titre en Chirurgie de la Main</Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              <Typography variant="span" weight="bold">Rester à votre écoute</Typography>
              {' '}
              et poser de nombreuses questions
            </Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              Prendre en compte vos enjeux (sportif, artistes,
              professionnels...)
            </Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              Savoir vous conseiller sur
              {' '}
              <Typography variant="span" weight="bold">les techniques les plus adaptés et les moins invasives</Typography>
            </Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              Appliquer les
              {' '}
              <Typography variant="span" weight="bold">techniques de pointes</Typography>
              {' '}
              (microchirugie, arthroscopie...)
            </Typography>
          </li>
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              Vous informer sur la pathologie, la cause, les différents traitements et leurs
              effets secondaires
            </Typography>
          </li>
        </ul>
        <Typography>
          Le Docteur Ghazanfari, spécialiste de la chirurgie des mains à Paris
          consulte sur 1 site :
        </Typography>
        <ul className="flex flex-col">
          <li className="relative pl-5 before:top-[15px] py-1 before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
            <Typography color="primary">
              <Typography variant="span" weight="bold">Clinique du Montgardé - SOS Main - Aubergenville</Typography>
              {' '}
              dans les Yvelines
            </Typography>
          </li>
        </ul>
        <Typography>
          Vous pouvez joindre son secrétariat ou prendre RDV en ligne (Doctolib) afin de
          convenir d&apos;un RDV avec ce praticien.
        </Typography>
      </div>
    </div>

    <div className="bg-background-light">
      <div className="flex flex-col px-4 py-12 mx-auto space-y-12 text-center max-w-screen-xlg">
        <Typography className="uppercase tracking-[0.3em]" variant="h2" color="white" size="lg" weight="bold">Les opérations de la main</Typography>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mdx:grid-cols-4">
          {opEnum.mains.items.map((op) => (
            <Link key={op.title} to={`/chirurgie-de-la-main/${op.url}`} className="flex flex-col shadow-lg">
              <Image className="h-full min-h-[150px] max-h-[150px]" imageName={op.previewImg} />
              <div className="flex h-full p-4 bg-white">
                <div className="flex flex-col w-full items-center p-4 space-y-4 border border-opacity-[0.75] border-[#e0dadc]">
                  <Typography variant="h3" color="primary" weight="bold">{op.title}</Typography>
                  <Separator height="h-[0.15rem]" width="w-[100px]" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  </Layout>
);

export default ChirurgieDeLaMain;
